import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import ImageSlider from "components/slider/ImageSlider";
import Footer from "components/footers/MiniCenteredFooter";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import ContactUs from "components/forms/SimpleContactUs";
import buildByProfis from "images/logo-pc.mp4";
import fallbackImageSrc from "images/PC-Profi.png";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import images from 'components/slider/loadImages'; // Import the images array

import PCimage3 from "images/PC-Image3.png";
import PCimage2 from "images/PC-Image2.png";
import PCsupport from "images/PC-Support.png";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <ImageSlider images={images} />
      <Features
        subheading={<Subheading>Angebot</Subheading>}
        description="Wir bieten dir eine breite Palette an Serviceleistungen rund um deinen PC."
        heading={
          <>
            Wir bieten einen kompetenten <HighlightedText>Service.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Gratis 24/7 Support",
            description: "Unser erfahrenes Team steht dir rund um die Uhr zur Verfügung, um dir bei jeglichen Problemen mit deinem PC zu helfen."
          },
          {
            imageSrc: CustomizeIconImage,
            title: "Individuelle Konfigurationen",
            description: "Wähle aus unseren hochwertigen Komponenten und baue dir deinen eigenen PC genau nach deinen Wünschen."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Expertenbau-Service",
            description: "Lass uns deinen PC für dich bauen und profitiere von unserer jahrelangen Erfahrung und Expertise."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Sicherheit",
            description: "Optimaler Schutz durch sichere Komponenten, regelmäßige Updates und erweiterte Funktionen."
          },
          { 
            imageSrc: CustomizeIconImage, 
            title: "Dein PC, Dein Design" ,
            description: "Mit unseren Customizable-Optionen kannst du deinen PC ganz nach deinen Wünschen gestalten. Von der Wahl des Gehäuses bis hin zur Auswahl der Komponenten, hast du die Freiheit, deinen PC so zu gestalten, wie es am besten zu dir passt."
          },
          { 
            imageSrc: ReliableIconImage, 
            title: "Zuverlässigkeit und Qualität",
            description: "Wir bieten dir einen zuverlässigen PC durch unsere jahrelange Erfahrung, hohen Qualitätsstandards.",
          },
          { 
            imageSrc: FastIconImage, 
            title: "Schnell",
            description: "Wir bauen deinen PC schnell und effizient, um sicherzustellen, dass du so schnell wie möglich mit deinem neuen Gerät arbeiten kannst.",
          },
          { 
            imageSrc: SimpleIconImage,
            title: "Einfach",
            description: "Unser einfaches Bestellprozess macht es dir leicht, deinen Traum-PC zu konfigurieren und zu bestellen."
          }
        ]}
      />
     <MainFeature 
      subheading={<Subheading>Qualität</Subheading>}
      heading = {(
        <>
          Konfiguriert & Gebaut von <span tw="text-primary-500">Profis.</span>
        </>
      )}
      imageSrc={buildByProfis}
      poster={fallbackImageSrc} // Add this line, and provide a valid URL for the fallback image
      video={true}
      imageBorder={true}
      imageDecoratorBlob={true}
      description="Ob du einen PC für deine Arbeit, Gaming, oder für kreative Projekte benötigst - bei uns findest du die perfekte Lösung. Unsere erfahrenen Techniker sorgen dafür, dass dein PC auf deine Bedürfnisse abgestimmt ist und dir eine optimale Performance bietet."
      primaryButtonText="Jetzt anfragen"
      primaryButtonUrl = "/#kontakt"

      
    />

      <MainFeature
        id="support"
        subheading={<Subheading></Subheading>}
        heading = {(
          <>
            24/7 Support
          </>
        )}
        imageSrc={PCsupport}
        imageBorder={true}
        imageDecoratorBlob={true}
        description="Gratis Support jederzeit: Mit unserem 24/7 Support hast du jederzeit Zugang zu unserem erfahrenen Team, das dir bei jeglichen Fragen und Problemen mit deinem PC zur Seite steht. Egal, ob es sich um eine kleine Konfigurationsänderung oder eine größere Reparatur handelt, wir sind hier, um dir zu helfen."
      />
      <FeatureWithSteps
        subheading={<Subheading>Schritte zu deinem Traum PC</Subheading>}
        heading={
          <>
            Einfach zu <HighlightedText>starten.</HighlightedText>
          </>
        }
        steps={[
          {
            heading: "Kontaktieren",
            description: "Kontaktiere uns einfach und bequem über unser Kontaktformular oder per Telefon, um deinen Traum-PC zu besprechen."
          },
          {
            heading: "Beratung und Offerte einholen",
            description: "Lass dich von unseren erfahrenen Beratern beraten und erhalte eine maßgeschneiderte Offerte für deinen individuellen PC-Bedarf."
          },
          {
            heading: "PC abholen",
            description: "Hole deinen neuen PC bei uns ab oder lass ihn dir bequem nach Hause liefern."
          },
          {
            heading: "Loslegen",
            description: "Leg einfach los und genieße die Leistung und Geschwindigkeit deines neuen Traum-PCs. Solltest du irgendwelche Fragen oder Probleme haben, steht dir unser 24/7 Support jederzeit zur Verfügung."
          }
        ]}
        textOnLeft={false}
        imageSrc={PCimage3}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Werte</Subheading>}
        heading={
          <>
            Immer an unseren <HighlightedText>Prinzipien</HighlightedText> festhalten
          </>
        }
        description="Wir stehen zu unseren Prinzipien und sorgen dafür, dass unser Angebot immer höchsten Qualitätsstandards entspricht. Unser Ziel ist es, dir den bestmöglichen Service zu bieten und dabei stets eine faire Preisgestaltung anzubieten."
        imageSrc={PCimage2}
        showDecoratorBlob={false}
        features={[
          {
            Icon: BriefcaseIcon,
            title: "Professionalität",
            description: "Wir versichern dir, dass alle unsere PCs von erfahrenen Experten zusammengestellt werden und dass wir bei der Auswahl der Komponenten stets auf hohe Qualität achten. Darüber hinaus bieten wir dir auch einen umfassenden Support, damit du jederzeit die Hilfe bekommst, die du benötigst.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
          {
            Icon: MoneyIcon,
            title: "Günstig",
            description: "Wir versprechen dir, dass wir dir die besten Preise bieten werden, die wir können, und dass diese im Einklang mit den Branchenstandards sind.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          }
        ]}
        buttonRounded={false}
        primaryButtonText="Jetzt anfragen"
        primaryButtonUrl = "/#kontakt"

      />
      <Pricing
        subheading={<Subheading>Preise</Subheading>}
        heading={
          <>
            Preisgünstige und flexible <HighlightedText>Pläne.</HighlightedText>
          </>
        }
        description="Wir bieten dir preisgünstige und flexible Lösungen, die perfekt auf deine Bedürfnisse zugeschnitten sind. Egal, ob du einen PC für den täglichen Gebrauch oder für besondere Anforderungen benötigst, wir haben den passenden Plan für dich. Darüber hinaus kannst du auch jederzeit auf einen höherwertigen Plan upgraden, wenn deine Bedürfnisse sich ändern."
        primaryButtonText="Jetzt anfragen"
        primaryButtonUrl = "/#kontakt"

        plans={[
          {
            name: "Beratung",
            price: "0.-",
            duration: "x Stunden",
            mainFeature: "",
            features: ["Kontakt mit Kunde", "Kundenwünsche einholen", "Kunde beraten"],
          },
          {
            name: "PC-Konfiguration",
            price: "99.-",
            duration: "2 Stunden",
            mainFeature: "",
            features: ["Kundenbedürfnisse definieren", "Setup zusammenstellen", "Kunde beraten"],
          },
          {
            name: "PC-Konfiguration + Zusammenbau",
            price: "299.-",
            additionalPrice: "Mind. 15% vom Gesamtpreis der Komponenten",
            duration: "8 Stunden",
            mainFeature: "",
            features: ["Vorsichtiger Zusammenbau", "Sauberes Kabelmanagement", "Windows Installation", "Treiberinstallation", "Professionelles Testing"],
            featured: true,
          },
          {
            name: "Custom Open Loop und Designer Builds",
            price: "Preis auf Anfrage",
            duration: "Dauer nach Aufwand 50.-/h",
            mainFeature: "Für Enthusiasten. Wir bieten dir die Möglichkeit, deinen PC mit einem individuellen Wasserkühlungssystem oder einem einzigartigen Designer Gehäuse zu bauen.",
            features: [],
          }
        ]}
      />
      {/*
       <Testimonial
        subheading={<Subheading>Bewertungen</Subheading>}
        heading={
          <>
            Unsere Kunden <HighlightedText>lieben uns.</HighlightedText>
          </>
        }
        description={"Unsere Kunden schätzen uns für unsere individuelle Beratung, unsere hohe Qualität und unser Engagement für Zufriedenheit. Lesen Sie selbst, was unsere zufriedenen Kunden über uns sagen!"}
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      />
      */}
     
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Haben Sie noch <HighlightedText>Fragen ?</HighlightedText>
          </>
        }
        description="Hier sind einige der häufig gestellten Fragen, die wir von unseren Kunden erhalten. Wenn Sie weitere Fragen haben, zögern Sie nicht, uns zu kontaktieren."
        faqs={[
          {
            question: "Wie lange dauert es, meinen PC zusammenzubauen?",
            answer:
              "Die Dauer des Zusammenbaus hängt von der Konfiguration ab, aber normalerweise dauert es 4-8 Stunden. Dazu kommt noch die Zeit, die für die Installation von Windows und Treibern benötigt wird sowie für das Testen des Systems."
          },
          {
            question: "Kann ich auch meine eigenen Komponenten verwenden?",
            answer:
              "Ja, wir bieten auch den Zusammenbau mit deinen eigenen Komponenten an."
          },
          {
            question: "Wie kann ich sicherstellen, dass mein PC auf meine Bedürfnisse abgestimmt ist?",
            answer:
              "Unsere erfahrenen Techniker beraten dich gerne und stellen sicher, dass dein PC auf deine spezifischen Bedürfnisse und Anforderungen abgestimmt ist. Wir berücksichtigen alle wichtigen Faktoren wie Leistung, Speicherkapazität, Grafikleistung und Kompatibilität, um sicherzustellen, dass du mit deinem PC zufrieden bist."
          },
          {
            question: "Kann ich meinen PC später aufrüsten?",
            answer:
              "Ja, du kannst deinen PC jederzeit aufrüsten, indem du weitere Komponenten hinzufügst. Wir bieten auch eine Upgrade-Service an, bei dem wir deinen PC für dich aufrüsten."
          },
          {
            question: "Was ist bei einer Rückgabe oder einem Umtausch zu beachten?",
            answer:
              "Da die Zusammenstellung eines PCs eine sehr individuelle Angelegenheit ist, können wir keine Rückgabe oder Umtausch anbieten. Wir bieten jedoch eine 1-Jahres-Garantie auf alle Komponenten beim Fachhändler an."
          }
        ]}
      />
      <ContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}
