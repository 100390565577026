function importAll(r) {
  // Map through each file, return an object with file and the corresponding key
  return r.keys().map(key => ({
    file: r(key),
    fileName: key // This is the file path used as key
  })).sort((a, b) => {
    // Compare file names, assuming you might have paths and need to sort by base name
    return a.fileName.localeCompare(b.fileName);
  });
}

const images = importAll(require.context('../../images/pcs', false, /\.(png|jpe?g|svg)$/));

export default images.map(image => image.file); // Export only the file paths if that's all you need
